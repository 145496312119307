import React from "react";
const data = {
  facebook: "fb.com",
  linkedin: "twitter.com",
};

const Contact = () => {
  return (
    <footer>
      <section id="contact">
        <div className="container">
          <div className="section-title text-center">
            <h2>Get In Touch</h2>
          </div>

          <div className="col-sm-12 contact-info">
            <div className="row">
              <div className="col-sm-4 contact-item">
                <div className="contact-container">
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  Radiusbacken 6 <br /> 126 29 Hägersten
                </div>
              </div>
              <div className="col-sm-4 contact-item">
                {/* <div className="contact-container">
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>
                  william.
                </div> */}
              </div>
              <div className="col-sm-4 contact-item">
                <div className="contact-container">
                  <span>
                    <i className="fa fa-building-o"></i> Organisation
                  </span>
                  559279-5438
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={data.linkedin}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href={data.facebook}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Contact;
