import React from "react";
const data = [
  {
    img: "img/clients/hemnet.png",
    alt: "Hemnet",
  },
  {
    img: "img/clients/sweco.jpg",
    alt: "Sweco",
  },
  {
    img: "img/clients/fyndiq.png",
    alt: "Fyndiq",
  },
  {
    img: "img/clients/leovegas.png",
    alt: "LeoVegas",
  },
  {
    img: "img/clients/netent.png",
    alt: "NetEnt",
  },
  {
    img: "img/clients/novamedia.png",
    alt: "Postkod Lotteriet",
  },
];

const Clients = () => {
  return (
    <section id="clients">
      <div className="container">
        <div className="section-title text-center">
          <h2>Previous clients</h2>
        </div>
        <div className="client-row">
          {data.map((client, i) => (
            <div key={`${client.alt}-${i}`} className="client-container">
              <div className="client-image">
                <img src={client.img} alt={client.alt} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Clients;
