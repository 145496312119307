import React from "react";

const data = [
  {
    icon: "fa fa-github",
    name: "Github",
    text: "Check out my Github",
    link: "https://github.com/willedanielsson",
  },
  {
    img: "img/bowly.png",
    alt: "Bowly App",
    name: "Bowly",
    text: "An app that uses AI to choose your daily outfit",
    link: "https://www.bowly.app/",
  },
  {
    icon: "fa fa-cutlery",
    name: "NomNom",
    text: "An app that allows you to swipe on nearby restaurants to find a place to eat, uses Machine learning to understand what kind of food you like",
  },
];

const Products = () => {
  return (
    <section id="products" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Products</h2>
          <p>Want to see what else we have done? Check us out below</p>
        </div>
        <div className="row">
          {data.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4 product">
              <a
                className="product-link"
                href={d.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {d.icon ? (
                  <i className={d.icon}></i>
                ) : (
                  <div className="product-image-container">
                    <img src={d.img} alt={d.alt} />
                  </div>
                )}
              </a>

              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Products;
