import React from "react";
const Header = () => {
  return (
    <header>
      <section id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>Wilada</h1>
                  <p>
                    We are a consulting company which offers dedicated,
                    proficient and social consultants that focuses on web
                    development throughout the whole stack, but are also
                    experienced in mobile development, agile coaching and much
                    more.
                  </p>
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
