import React from "react";
const About = () => {
  const whyText = ["React", "React Native", "Node.js", "Lead development"];

  const whyTextTwo = ["Ruby on Rails", "Agile coaching"];
  return (
    <section id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img
              src="img/william_danielsson.jpg"
              className="img-responsive"
              alt="William Danielsson - Founder of Wilada AB"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>
                Hi! My name is William Danielsson and I am the founder of Wilada
                Consulting. I created this company after working for some larger
                consultancy firms and felt that the personal connection between
                consultant and client was being diminished. The idea of Wilada
                is to allow our consultants to more freely be able to control
                their work and therefore be happier with their client, something
                that benefits the client as well. If you want to know more about
                us or our available consultants, hit us up!
              </p>
              <h3>Our skillsets</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {whyText.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {whyTextTwo.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
