import React from "react";
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import About from "./components/About";
import Products from "./components/Products";
import Clients from "./components/Clients";
import Contact from "./components/Contact";

const App = () => {
  return (
    <div>
      <Navigation />
      <Header />
      <About />
      <Clients />
      <Products />
      <Contact />
    </div>
  );
};

export default App;
